<template>
<div>
    <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <lgs-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :RequirementEdit="RequirementEdit"></lgs-requirement-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogOrder" v-model="dialogOrder" persistent transition="dialog-bottom-transition">
            <order-edit @onClose="dialogOrder = false;" @onCloseClear="dialogOrder = false;" :value="DocEntry"></order-edit>
        </v-dialog>
    </div>
    <!-- <div v-if="dialogCot">
        <v-dialog class="elevation-0" v-model="dialogCot" width="100%" persistent transition="dialog-bottom-transition">
            <order-cotization-edit @onClose="dialogCot = false;" :value="CttID"></order-cotization-edit>
        </v-dialog>
    </div> -->

    <!-- LISTADO DE REQUERIMEINTOS POR TIPO   -->
    <v-row>
        <v-col style="padding-bottom: 0px">
            <s-crud class="elevation-0 table table-hover table-condensed" noFull height="200" ref="grid" :view="selected.length > 0 ? (selected[0].ReqStatus != 5? true : false) : false" @rowSelected="rowSelected($event)" search-input="" add-no-function :filter="filterRequirement" @add="add()" :title="label" add @edit="edit()" :remove="selected.length > 0 ? (selected[0].ReqStatus == 4 || selected[0].ReqStatus == 1  ? true : false) : false" delete-no-function @delete="cancelApproval(ReqID)" :config="config">
                <!-- <template v-slot:options>
                    <div>
                        <v-btn v-if="selected.length > 0 ? (selected[0].ReqStatus == 4 && $fun.isAdmin() && selected[0].ReqCircuitAnulated == null ? true : false) : false" elevation="0" x-small color="#5175af" @click="cancelApproval(ReqID)">
                            <i class="fas fa-user-slash"></i>
                        </v-btn>
                    </div>
                </template> -->

                <template v-slot:filter>
                    <v-container>
                        <v-container>
                            <v-row justify="center">

                                <v-col lg="2" md="2" cols="6" class="s-col-form">
                                    <s-select-definition label="Empresa" clearable no-default v-model="filterRequirement.typeBusiness" :def="1142" />
                                </v-col>

                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-select-definition clearable label="Estados" noDefault v-model="filterRequirement.ReqStatus" :def="1157" :dgr="47" />
                                </v-col>
                                <v-col lg="3" md="3" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                                    <c-select-area full clearable noDefault v-model="filterRequirement.AreID" label="Área" :typeArea="3" />
                                </v-col>
                                <v-col lg="2" md="3" cols="6" class="s-col-form">
                                    <s-date clearable v-model="filterRequirement.BeginDate" label="Fecha Inicio" />
                                </v-col>
                                <v-col lg="2" md="3" cols="6" class="s-col-form">
                                    <s-date clearable v-model="filterRequirement.EndDate" label="Fecha Fin" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </template>
                <template v-slot:ReqStatusName="{ row }">
                    <v-chip x-small :color="row.ReqStatusColor">
                        {{ row.ReqStatusName }}<span>
                            <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="dialogApprovalRequirement= true"><i class="fas fa-eye"></i></v-btn>
                        </span>
                    </v-chip>

                </template>

                <template v-slot:TypePriorityName="{ row }">
                    <v-chip x-small :color="row.TypePriorityColor">
                        {{ row.TypePriorityName }}
                    </v-chip>
                </template>

                <template v-slot:options>
                    <v-btn title="Asociar a Circuito" elevation="0" v-if="selected.length > 0 ? selected[0].ReqStatus == 4 && selected[0].ReqExistsCircuit == 0 && selected[0].UsrID == $fun.getUserID() ? true:false:false" style="text-transform:capitalize" small icon @click="associateCircuit()">
                        <v-icon style="font-size: 16px">fa-solid fa-paper-plane</v-icon>
                    </v-btn>
                </template>

                <!-- <template v-slot:CttDocumentNumber="{ row }">
                    <a style="text-decoration:underline; color:black" @click="showCtt(row, 1)">{{ row.CttDocumentNumber }}</a>
                </template> -->

                <!--  <template v-slot:MailOK="{ row }">
                    {{row.listEmail.length}}
                    <v-tooltip top="">
                        <template v-slot:activator="{ on }" x-small>
                            <v-btn v-on="on" icon small :color="row.listEmail.length > 0 ? 'success' : 'error'" @click="oprenDialogHtml(row)">
                                <i class="fas fa-envelope"></i>
                            </v-btn>
                        </template>
                        <span>{{row.listEmail.length > 0 ? 'Email enviado' : 'Error al enviar Email'}}</span></v-tooltip>
                </template> -->

            </s-crud>
        </v-col>
    </v-row>

    <!--LISTADO DE   DETALLE DEL REQUERIMIENTO-->
    <v-col style="padding: 0px; margin-top: 17px">
        <s-toolbar :remove="itemsDetailSelect.length > 0 ? itemsDetailSelect[0].DerStatus == 8 : false" label="Detalle" @removed="removed(itemsDetailSelect)"></s-toolbar>
        <!-- TABLA DETALLE DE REQUERIMIENTO -->
        <v-data-table v-if="selected.length > 0" dense :items="itemsDetail" @dblclick:row="dblClick" show-select single-select v-model="itemsDetailSelect" :headers="headersDetail" item-key="DerID">

            <template v-slot:item.DerStatusName="{ item }">
                <v-chip x-small :color="item.DerStatusColor">
                    <b>{{ item.DerStatusName }}</b>
                </v-chip>
            </template>

            <template v-slot:item.TypeRequirement="{ item }">
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :color="
                                    item.TypeRequirement == 1 ? 'red' : 'green'
                                " style="font-size:16px;">
                            {{
                                item.TypeRequirement == 1 ? 'fas fa-car': 'fas fa-street-view'
                            }}

                        </v-icon>
                    </template>
                    <span>{{item.TypeRequirement == 1 ? 'Compra': 'Servicio'}}</span>
                </v-tooltip>
            </template>

            <template v-slot:item.OrdDate="{ item }">
                {{ $fun.formatDateView(item.OrdDate) }}
            </template>
            <template v-slot:item.OrdDocumentNumber="{ item }">
                <a style="text-decoration:underline" @click="openOrder(item)">{{ item.OrdDocumentNumber }}</a>
            </template>

        </v-data-table>
        <v-img contain height="200" style="margin-top: -50px;opacity: 0.3;" src="../../../assets/cancelado.jpg" v-if="selected.length > 0 ? (selected[0].ReqCircuitAnulated ?true:false):false"></v-img>
    </v-col>

    <!-- NIVELES DE APROBACION DE OC-->
    <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="2" />
    </v-dialog>

    <!-- NIVELES DE APROBACION DE REQ-->
    <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="ReqID" :TypeObject="1" />
    </v-dialog>

    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="messageProcessing" @close="processing=false;messageProcessing=''" />
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <vc-modal-circuit close :circuits="circuits" :TrsID="1" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" />

    <!--  <v-dialog v-model="dialogHtmlEmail" v-if="dialogHtmlEmail" persistent fullscreen  >
        <s-toolbar close @close="dialogHtmlEmail=false;" :label="'Plantilla Correo enviado'"></s-toolbar>
       <v-row style="background-color:#EEEEEE">
            <v-col v-for="body in miHtmlEmail" cols="12" lg="6" md="6" v-html="body.MailBody" :key="body.MailBody">
            </v-col>
        </v-row>
    </v-dialog> -->

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/RequirementService";
import _sRequirementDetail from "@/services/Logistics/RequirementDetailService";
import _sTransaction from '@/services/Security/ApprovalTransactionService'

//Components
import LgsRequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import OrderCotizationEdit from '../OrderCotization/OrderCotizationEdit.vue';
/* import signalr from "signalr"; */
/* import VueHtml2pdf from 'vue-html2pdf' */

export default {
    name: "",
    components: {
        LgsRequirementEdit,
        ApprovalLevelsDocument,
        OrderEdit,
        OrderCotizationEdit
        /*  VueHtml2pdf */
    },
    props: {
        label: {
            default: "Requerimiento de Compra",
            type: String
        },
    },
    data() {
        return {
            OrdID: null,
            CttID: null,
            dialogOrder: false,
            dialogApproval: false,
            dialogApprovalRequirement: false,
            dialogCot: false,
            dialogHistory: false,
            itemHistory: [],
            itemReq: {},
            DocEntry: null,
            ReqID: null,
            RequirementEdit: {},
            TypeCost: 0,
            centercost: 0,
            bslID: 0,
            dialog: false,
            TypeRequirement: 1,
            filterRequirement: {
                BeginDate: null,
                EndDate: null,
                TypeRequirement: 1,
                AreID: 0,
                ReqStatus: 4,
                typeBusiness: null
            },
            selected: [],
            itemsDetail: [],
            itemsDetailSelect: [],
            config: {
                model: {
                    ReqDocumentNumber: "ID",
                    ReqDate: "datetime",
                    SecUpdate: "datetime",
                    ReqStatusName: "",
                    TypePriorityName: "string",
                    TypeCotizacionName: "string",
                    CttDocumentNumber: "",
                    MailOK: "string"
                },
                service: _sRequirement,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },
                    {
                        text: "Empresa",
                        value: "nameBusiness",
                        sortable: false,
                        width: 60,
                    },
                    {
                        text: "N° Requerimiento",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 155,
                    },

                    {
                        text: "N° Cotización",
                        value: "CttDocumentNumber",
                        sortable: false,
                        width: 100,

                    },
                    {
                        text: "Nro. OC/OS",
                        value: "OrdDocumentNumber",
                        sortable: false,
                        width: 100,
                        align: "center"
                    },

                    {
                        text: "Fecha",
                        value: "ReqDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Solicitante",
                        value: "UsrName",
                        sortable: false,

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Mod",
                        value: "SecUpdate",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Área",
                        value: "AreName",
                        sortable: false
                    },
                    /* {
                        text: "Ok",
                        value: "MailOK",
                        sortable: false
                    }, */

                ],
            },
            headersDetail: [{
                    text: "ID",
                    value: "DerID",
                    sortable: false,
                    width: 10,
                    align: 'center'
                }, {
                    text: "Tipo",
                    value: "TypeRequirement",
                    sortable: false,
                    width: 10,
                    align: 'center'
                },
                {
                    text: "Descripción",
                    value: "DerDescription",
                    sortable: false
                },
                {
                    text: "Comentario",
                    value: "DerObservation",
                    sortable: false
                },
                {
                    text: "Cant. Solicitada",
                    value: "DerQuantity",
                    sortable: false,
                    width: 70,
                    align: "center"
                },
                {
                    text: "UM",
                    value: "UnitMeasurement",
                    sortable: false,
                    width: 70,
                    align: "center"
                },
                {
                    text: "Nro. OC/OS",
                    value: "OrdDocumentNumber",
                    sortable: false,
                    width: 70,
                    align: "center"
                },
                {
                    text: "Estado",
                    value: "DerStatusName",
                    sortable: false
                },
            ],
            miHtmlEmail: [],
            dialogHtmlEmail: false,
            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],

            //Circuitos
            circuits: [],
            dialogCircuit: false,
        }
    },
    created() {
        // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
        alert("Desde el lunes 18-09 los nuevos requerimientos seran gestionados desde la nueva plataforma: https://appv2.imperu.com.pe/ , debe ingresar con las mismas credenciales de acceso.")
        //window.location = "https://appv2.imperu.com.pe/"



        if (!this.$fun.isAdmin()) this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;
    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },

    },

    methods: {

        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },

        //seleccionar registro por defecto
        rowSelected(item) {

            this.selected = item;
            console.log(this.selected)

            if (item != null) {
                if (item.length > 0) {
                    if (item[0].ReqID != null)
                        this.ReqID = item[0].ReqID

                    _sRequirementDetail.detail({
                        ReqID: item[0].ReqID
                    }, this.$fun.getUserID()).then((r) => {
                        this.itemsDetail = r.data;
                    });
                }
            }
        },

        dblClick(e, {
            item
        }) {

            if (item.OrdID != null) {
                this.DocEntry = item.OrdID;
                this.dialogApproval = true;
            }
        },

        //Nuevo Registro
        add() {

            this.RequirementEdit.ReqID = null;
            this.dialog = true;
        },

        //Editar Requerimiento
        edit() {
            this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
            this.RequirementEdit.items = this.itemsDetail;
            this.dialog = true;
        },

        //Remover item de Detalle
        removed(items) {
            /* if(this.itemsDetail.length == 1){
                alert("Error no se puede anular el unico item.")
                return
            } */
            this.$fun.alert("Seguro de Anular?", "question", false).then((r) => {
                if (r.value) {
                    items.map((item) => {
                        item.DerStatus = 9;
                        item.UsrUpdateID = this.$fun.getUserID()
                        item.Details = []
                        return item;
                    });
                    _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
                        this.$fun.alert("Anulado Correctamente", "success");
                        this.rowSelected(this.selected);
                        this.itemsDetailSelect = [];
                    });
                }
            });
        },

        cancelApproval(ReqID) {
            this.$fun.alert("Seguro de Anular Requerimiento?", "question").then((r) => {
                if (r.value) {
                    _sTransaction.cancelApproval(ReqID, 1, this.$fun.getUserID()).then(() => {
                        this.$fun.alert("Requerimiento Anulado Correctamente", "success");
                        this.$refs.grid.refresh();
                    });
                }
            });
        },
        openOrder(item) {
            if (item.OrdID != null) {
                this.DocEntry = item.OrdID;
                this.dialogOrder = true;
            }
        },

        /* showCtt(item, op){
            console.log("ctt",item, op)
        }, */

        openHistory(item) {
            console.log(item)
            this.itemReq = item

            _sRequirementDetail.history(item, this.$fun.getUserID()).then(resp => {
                console.log(resp)
                this.itemHistory = resp.data
            })

            this.dialogHistory = true
        },

        oprenDialogHtml(item) {
            console.log("itemmmmmmmmmmm", item)

            if (item.listEmail == null) {
                this.dialogHtmlEmail = false
                this.$fun.alert("No existe Plantilla de correo", "error")
            } else {
                this.miHtmlEmail = item.listEmail
                this.dialogHtmlEmail = true
            }

        },

        associateCircuit() {

            console.log("associate", this.selected);

            if (this.selected.length > 0) {
                this.$fun
                    .alert("Asociar Requerimiento a Circuito, Desea Continuar: ?.", "question")
                    .then((r) => {
                        if (r.value) {

                            this.messageProcessing = ""
                            this.processing = true
                            _sTransaction
                                .getCircuit(this.selected[0], this.$fun.getUserID())
                                .then(
                                    (j) => {
                                        this.messageProcessing = ""
                                        this.processing = false;

                                        if (j.data.length > 0) {
                                            j.data.forEach(element => {
                                                element.DocEntry = this.selected[0].ReqID
                                            });

                                            this.circuits = j.data;
                                            this.dialogCircuit = true;
                                        }
                                    },
                                    (e) => {

                                        this.messageProcessing = e.response.data.Message;
                                        this.processing = true;
                                    }
                                );
                        }
                    });

            }

        }
    },

    /* mounted() {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
        document.head.appendChild(recaptchaScript);

        recaptchaScript.addEventListener("load", () => {
            $.connection.hub.url = this.$const.URL + "/signalr";
            var chat = $.connection.notification;
            chat.client.sendMessageGroup = (not, group) => {
                this.filterRequirement = {};
            };

            $.connection.hub.start({
                jsonp: true
            }).done(e => {
                if (this.$fun.getUserInfo().CtrContract)
                    chat.invoke(
                        "addToGroup",
                        this.$fun.getUserInfo().CtrContract.PstID
                    );
            });
        });
    }, */

};
</script>
